<template>
  <div class="detailsContent" ref="detailsContent">
    <div class="detailsRichIntro" v-if="handleRichIntro!==''" v-html="handleRichIntro"></div>
    <div class="detailsIntro" v-if="handleIntro.length !== 0 && handleRichIntro ===''">
      <DisplayComponent v-for="(item,index) in handleIntro" :key="index" :item="item"></DisplayComponent>
    </div>
  </div>
</template>
<script>
import imagesloaded from 'imagesloaded';
import { Base64 } from "js-base64";
import { getPersonnelDetails } from "@/api/webApi";
import DisplayComponent from "../../components/business/newVersion/common/displayComponent";
export default {
  components: {
    DisplayComponent
  },
  data() {
    return {
      // 用户ID
      userId: "",
      // 当前客户端语言
      language: 1,
      // 用户信息
      userInfo: {}
    }
  },
  mounted() {
    try{
      if(this.$route.params.id.indexOf("pai_") !== -1){
        this.userId = Base64.decode(this.$route.params.id.replace("pai_", ""));
      }else {
        this.userId = this.$route.params.id;
      }
    } catch (e) {}
    this.language = this.$route.params.language || 1;
    localStorage.setItem("langCode",this.language)
    this.$nextTick(() => {
      this.handlerGetUserinfo();
    })
  },
  computed: {
    // 处理过的富文本字符串
    handleRichIntro() {
      let handleRichIntro = ""
      if(this.userInfo.richIntro && this.userInfo.richIntro !== "") {
        try {
          handleRichIntro = this.userInfo.richIntro.replace(RegExp("width:500px", "g"),"max-width:500px").replace(RegExp("_blank", "g"),"_self");
        } catch (error) {console.log(error,this.userInfo.richIntro)}
      }
      return handleRichIntro;
    },
    // 处理过的客户端数据
    handleIntro() {
      let handleIntro = [];
      if(this.userInfo.intro && this.userInfo.intro !== "") {
        try {
          handleIntro = JSON.parse(this.userInfo.intro.replace(/\n/g,""));
        } catch (error) {console.log(error,this.userInfo.intro)}
      }
      return handleIntro;
    }
  },
  methods: {
    // 获取用户的详细信息
    async handlerGetUserinfo() {
      let result = await getPersonnelDetails(this.userId).catch(e => {});
      if(result.code == 200) {
        this.userInfo = result.data.data;
        this.$forceUpdate();
        this.$nextTick(()=>{
          this.setPageHeight();
        })
      } else {
        this.$toast.fail(result.message || '')
      }
    },
    // 将页面高度传给客户端
    setPageHeight() {
      imagesloaded('.detailsContent', () => {
        setTimeout(() => {
          try{
            window.webkit.messageHandlers.setPageHeight.postMessage(this.$refs.detailsContent.offsetHeight);
          }catch(e){}
          try{
            let result = window.android.setPageHeight(this.$refs.detailsContent.offsetHeight);
          }catch(e){}
        },100)
      });
    },
  }
}
</script>
<style lang="stylus">
  @import './detailsForApp'
  .detailsContent
    background #FFFFFF;
    img
      max-width 100% !important;
    video
      max-width 100% !important;

</style>