var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "detailsContent", staticClass: "detailsContent" }, [
    _vm.handleRichIntro !== ""
      ? _c("div", {
          staticClass: "detailsRichIntro",
          domProps: { innerHTML: _vm._s(_vm.handleRichIntro) }
        })
      : _vm._e(),
    _vm.handleIntro.length !== 0 && _vm.handleRichIntro === ""
      ? _c(
          "div",
          { staticClass: "detailsIntro" },
          _vm._l(_vm.handleIntro, function(item, index) {
            return _c("DisplayComponent", { key: index, attrs: { item: item } })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }